<template>
  <form @submit.prevent="validateForm()" data-vv-scope="tax-form" action>
    <v-row>
      <v-col cols="12" class="py-0">
        <label>Nombre</label>
        <v-text-field
          outlined
          dense
          hide-details
          v-model="tax.name"
          data-vv-validate-on="blur"
          v-validate="'required'"
          data-vv-name="name"
          :error-messages="errors.collect('tax-form.name')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <label>Porcentaje</label>
        <v-text-field
          outlined
          dense
          hide-details
          v-model="tax.percentage"
          data-vv-validate-on="blur"
          v-validate="'required|min_value:0|max_value:100'"
          data-vv-name="percentage"
          :error-messages="errors.collect('tax-form.percentage')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <label>Incluida</label>
        <v-select
          :items="yesNo"
          outlined
          dense
          hide-details
          v-model="tax.included"
          data-vv-validate-on="blur"
          v-validate="'required'"
          data-vv-name="included"
          :error-messages="errors.collect('tax-form.included')"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <label>Región</label>
        <v-text-field
          outlined
          dense
          hide-details
          v-model="tax.region"
          data-vv-validate-on="blur"
          v-validate="'required'"
          data-vv-name="region"
          :error-messages="errors.collect('tax-form.region')"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6" class="py-0">
        <label>Por defecto</label>
        <v-select
          :items="yesNo"
          outlined
          dense
          hide-details
          v-model="tax.default"
          data-vv-validate-on="blur"
          v-validate="'required'"
          data-vv-name="default"
          :error-messages="errors.collect('tax-form.default')"
        ></v-select>
      </v-col>
      <v-col cols="12" class="py-0">
        <label>Descripción</label>
        <v-text-field
          outlined
          dense
          hide-details
          v-model="tax.description"
          data-vv-validate-on="blur"
          v-validate="'required'"
          data-vv-name="description"
          :error-messages="errors.collect('tax-form.description')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-spacer></v-spacer>
      <v-btn @click="$emit('cancel')" outlined height="30px">
        {{ $t("cancel") }}
      </v-btn>
      <v-btn type="submit" height="30px" elevation="0" class="ml-1">
        <v-icon size="14px" class="mr-1">$save</v-icon>
        {{ $t("save", { name: "" }) }}
      </v-btn>
    </v-row>
  </form>
</template>
<script>
export default {
  name: "TaxesForm",
  props: {
    tax: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      yesNo: [
        { text: this.$t("yes"), value: 1 },
        { text: this.$t("no"), value: 0 },
      ],
    };
  },
  methods: {
    async validateForm() {
      let form_val = await this.$validator.validateAll("tax-form");
      console.log("form_val", form_val);
      if (form_val) {
        this.$emit("validated");
      }
    },
  },
};
</script>

<style scoped lang="sass">
label
  display: block
  height: 25px
</style>
